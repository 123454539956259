import React from 'react';

export default function ActivityRow({ activity, index }) {

    let statusStyle = {};
    if (activity.statoRichiesta === 'Confirmed') { statusStyle = { color: '#21EE1D'} } 
    if (activity.statoRichiesta === 'Cancelled') { statusStyle = { color: 'red' } }

    return (

        <tr key={index}>
            <td><a href={'/partnerprofile/' + activity.guid}>{activity.ragioneSociale}</a></td>
            <td>{activity.slot}</td>
            <td style={statusStyle}>{activity.statoRichiesta}</td>
        </tr>

    );

}
