import React, { useState, useEffect } from 'react';
import { getAdminWebClient } from '../../helpers/Helpers.js';

export default function AdminStampaAgendeBuyer() {

    const [agendas, setAgendas] = useState();
    const client = getAdminWebClient();

    useEffect(() => {

        client.get(`/api/admin/manageagendas/buyer`)
            .then(function (profilesResponse) {
                setAgendas(profilesResponse.data);
            })
            .catch(function (error) {
                console.log(error);
                //setViewMode('error')
            });

    }, []);

    let agendasRows;

    if (agendas) {

        agendasRows = agendas.map((agenda, index) => {

            return (

                <>

                    {agenda.date.map(function (data, index2) {
                        return (
                            <div className="container pagina">
                                <center>
                                    <br></br>
                                    <h4>{agenda.ragioneSociale} - {data.data}</h4>
                                    <br></br>
                                </center>

                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Time</th>
                                            <th>Appointment with</th>
                                            <th>Stand</th>
                                            <th>Queued with</th>
                                            <th>Stand</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {data.appuntamenti.map(function (appuntamento, index3) {
                                            return (

                                                <tr>
                                                    <td>{appuntamento.oraDa} - {appuntamento.oraA}</td>
                                                    <td>{appuntamento.partner ? appuntamento.partner : '-'}</td>
                                                    <td>{appuntamento.partnerStand ? appuntamento.partnerStand : '-'}</td>
                                                    <td>{appuntamento.queuedPartner ? appuntamento.queuedPartner : '-'}</td>
                                                    <td>{appuntamento.queuedPartnerStand ? appuntamento.queuedPartnerStand : '-'}</td>
                                                </tr>

                                            );
                                        })}

                                    </tbody>
                                </table>
                            </div>
                        );
                    })}

                </>

            );

        });

    };

    return (
        <>

<style
  dangerouslySetInnerHTML={{
    __html:
      "\n\n                html,\n                body {\n                    margin-left: auto;\n                    margin-right: auto;\n                    margin-top: 0mm;\n                    padding: 0;\n                    width: 21cm;\n                    height: 29.7cm;\n                }\n\n                @media print {\n\n                    .pagina {\n                        margin-left: auto;\n                        margin-right: auto;\n                        margin-top: 0mm;\n                        padding: 0;\n                        width: 21cm;\n                        height: 29.7cm;\n                        page-break-after: always;\n                        size: A4 portrait;\n                    }\n                }\n\n            "
  }}
/>

            {agendasRows}

        </>
    )
}
