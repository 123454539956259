import React from 'react';

export default function Navbar({ setAdminAuthenticationToken }) {

    function handleAdminLogoutClick() {
        setAdminAuthenticationToken(null);
        localStorage.removeItem("adminAuthenticationToken");
    }

    return (
        <>
            <nav className="navbar navbar-expand-lg bg-body-tertiary">
                <div className="container">
                    <a className="navbar-brand" href="/admin">
                        SIHE - Area amministrativa
                    </a>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon" />
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">

                            <li className="nav-item">
                                <a className="nav-link" aria-current="page" href="/admin/anagrafiche">Anagrafiche</a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link" aria-current="page" href="/admin/appuntamentibuyer">Appuntamenti</a>
                            </li>

                            <li className="nav-item dropdown">
                                <a
                                    className="nav-link dropdown-toggle"
                                    href="/#"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    Stampa agende
                                </a>
                                <ul className="dropdown-menu">
                                    <li>
                                        <a className="dropdown-item" href="/admin/stampaagendebuyer" target="_blank">
                                            Buyer
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="/admin/stampaagendeseller" target="_blank">
                                            Seller
                                        </a>
                                    </li>
                                </ul>
                            </li>

                            <li className="nav-item">
                                <button type="button" className="btn btn-link" onClick={handleAdminLogoutClick} style={{ textDecoration: "none", color: "inherit" }}>Logout</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>


        </>
    );
}
