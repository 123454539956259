import axios from 'axios';

export function getAuthenticationToken() {
    return localStorage.getItem("authenticationToken");
}

export function getAdminAuthenticationToken() {
    return localStorage.getItem("AdminAuthenticationToken");
}

export function getWebClient() {

    let client;

    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
        client = axios.create({
            baseURL: 'http://localhost:5132',
            headers: {
                'Content-Type': 'application/json',
                'ApiAuthenticationToken': localStorage.getItem("authenticationToken")
            }
        });
    } else {
        client = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'ApiAuthenticationToken': localStorage.getItem("authenticationToken")
            }
        });
    }

    return client;
}

export function getAdminWebClient() {

    let client;

    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
        client = axios.create({
            baseURL: 'http://localhost:5132',
            headers: {
                'Content-Type': 'application/json',
                'AdminApiAuthenticationToken': localStorage.getItem("adminAuthenticationToken")
            }
        });
    } else {
        client = axios.create({
            headers: {
                'Content-Type': 'application/json',
                'AdminApiAuthenticationToken': localStorage.getItem("adminAuthenticationToken")
            }
        });
    }

    return client;
}

export function getDataTablesLanguage() {

    return {
        "decimal": "",
        "emptyTable": "Dati non disponibili per la visualizzazione",
        "info": "Visualizzati da _START_ a _END_ di _TOTAL_ record totali",
        "infoEmpty": "Visualizzati da 0 a 0 di 0 record totali",
        "infoFiltered": "(filtrati da _MAX_ record totali)",
        "infoPostFix": "",
        "thousands": ",",
        "lengthMenu": "Visualizza _MENU_ record per pagina",
        "loadingRecords": "Caricamento...",
        "processing": "",
        "search": "Ricerca:",
        "zeroRecords": "Nessun record trovato con i criteri impostati",
        "paginate": {
            "first": "Primo",
            "last": "Ultimo",
            "next": "Successivo",
            "previous": "Precedente"
        },
        "aria": {
            "orderable": "Order by this column",
            "orderableReverse": "Reverse order this column"
        }
    };

}
