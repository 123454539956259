import React, { useState, useEffect } from 'react';
import Navbar from './components/Navbar';
import { getAdminWebClient, getDataTablesLanguage } from '../../helpers/Helpers.js';
import DataTable from 'datatables.net-react';
import DT from 'datatables.net-bs5';

export default function AdminAppuntamentiBuyer({ setAdminAuthenticationToken }) {

    DataTable.use(DT);

    const columns = [
        { data: 'ragioneSociale' },
        { data: 'appuntamentiProposti' },
        { data: 'appuntamentiCodaProposti' },
        { data: 'appuntamentiAbilitati' },
        { data: 'appuntamentiCodaAbilitati' },
    ];

    const options = {
        language: getDataTablesLanguage(),
        order: [[3, 'desc']],
        ordering: true
    };

    const [tableData, setTableData] = useState([]);  

    useEffect(() => {

        const client = getAdminWebClient();

        client.get(`/api/admin/getbuyerappointments`)
            .then(function (response) {
                setTableData(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });

    }, []);

    return (
        <>

            <link href="https://cdn.datatables.net/v/bs5/dt-2.1.8/datatables.min.css" rel="stylesheet"></link>

            <Navbar setAdminAuthenticationToken={setAdminAuthenticationToken} />

            <div className="container" style={{ marginTop: 20, marginBottom: 20 }} >
                <h4>Distinta appuntamenti Buyer</h4>
            </div>

            <div className="container">
                <DataTable data={tableData} columns={columns} options={options} className="table table-striped">
                    <thead>
                        <tr>
                            <th>Ragione sociale</th>
                            <th>Proposti</th>
                            <th>Proposti, in coda</th>
                            <th>Confermati</th>
                            <th>Confermati, in coda</th>
                        </tr>
                    </thead>
                </DataTable>
            </div>

        </>
    )
}
