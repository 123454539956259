import React, { useState, useEffect } from 'react';
import Navbar from './components/Navbar';
import { getAdminWebClient } from '../../helpers/Helpers.js';

export default function AdminAnagrafiche({ setAdminAuthenticationToken }) {

    if (localStorage.getItem("showBuyer") === null) { localStorage.setItem("showBuyer", true); }
    if (localStorage.getItem("showSeller") === null) { localStorage.setItem("showSeller", true); }
    if (localStorage.getItem("showAbilitati") === null) { localStorage.setItem("showAbilitati", true); }
    if (localStorage.getItem("showDisabilitati") === null) { localStorage.setItem("showDisabilitati", true); }

    const [viewMode, setViewMode] = useState('waiting');
    const [profiles, setProfiles] = useState();
    const [showBuyer, setShowBuyer] = useState(localStorage.getItem("showBuyer") === 'true');
    const [showSeller, setShowSeller] = useState(localStorage.getItem("showSeller") === 'true');
    const [showAbilitati, setShowAbilitati] = useState(localStorage.getItem("showAbilitati") === 'true');
    const [showDisabilitati, setShowDisabilitati] = useState(localStorage.getItem("showDisabilitati") === 'true');
    const [sortBy, setSortBy] = useState('ragioneSociale');
    const [sortDirectionAscending, setSortDirectionAscending] = useState(true);

    const client = getAdminWebClient();

    function handleSortClick(sortField) {
        if (sortBy === sortField) {
            setSortDirectionAscending(!sortDirectionAscending);
        } else {
            setSortDirectionAscending(true);
        }

        setSortBy(sortField);
    }

    useEffect(() => {

        client.get(`/api/admin/managecompanydata`)
            .then(function (profilesResponse) {
                setProfiles(profilesResponse.data);
            })
            .catch(function (error) {
                console.log(error);
                setViewMode('error')
            });

    }, []);

    localStorage.setItem("showBuyer", showBuyer);
    localStorage.setItem("showSeller", showSeller);
    localStorage.setItem("showAbilitati", showAbilitati);
    localStorage.setItem("showDisabilitati", showDisabilitati);

    let profilesRows;
    let profilesNumber = 0;

    if (profiles) {

        let sortedProfiles = profiles;

        if (sortBy === 'ragioneSociale') {
            sortedProfiles = profiles.sort((a, b) => {
                if (!a.ragioneSociale) { return -1; }
                if (!b.ragioneSociale) { return +1; }
                return a.ragioneSociale.localeCompare(b.ragioneSociale)
            });
        }

        if (sortBy === 'nazione') {
            sortedProfiles = profiles.sort((a, b) => {
                if (!a.nazione) { return -1; }
                if (!b.nazione) { return +1; }
                return a.nazione.localeCompare(b.nazione)
            });
        }

        if (sortBy === 'localita') {
            sortedProfiles = profiles.sort((a, b) => {
                if (!a.localita) { return -1; }
                if (!b.localita) { return +1; }
                return a.localita.localeCompare(b.localita)
            });
        }

        if (sortBy === 'seller') {
            sortedProfiles = profiles.sort((a, b) => {
                if (!a.seller) { return -1; }
                if (!b.seller) { return +1; }
                return a.seller < b.seller;
            });
        }

        if (sortBy === 'abilitato') {
            sortedProfiles = profiles.sort((a, b) => {
                if (!a.abilitato) { return -1; }
                if (!b.abilitato) { return +1; }
                return a.abilitato < b.abilitato;
            });
        }

        if (!sortDirectionAscending) { sortedProfiles = sortedProfiles.reverse(); }

        profilesRows = sortedProfiles.map((profile, index) => {

            let rowStyle;
            if (profile.abilitato) { rowStyle = { backgroundColor: "#00ff0010" } } else { rowStyle = { backgroundColor: "#ff000010" } }

            if (((profile.seller && showSeller) || (!profile.seller && showBuyer)) && ((profile.abilitato && showAbilitati) || (!profile.abilitato && showDisabilitati))) {
                profilesNumber++;
                return (
                    <tr key={index}>
                        <td style={rowStyle}><a href={'/admin/anagrafiche/edit/' + profile.guid}>{profile.ragioneSociale}</a></td>
                        <td style={rowStyle}>{profile.localita}</td>
                        <td style={rowStyle}>{profile.nazione}</td>
                        <td style={rowStyle}>{profile.seller ? 'Seller' : 'Buyer'}</td>
                        <td style={rowStyle}>{profile.abilitato ? 'Si' : 'No'}</td>
                    </tr>
                );
            } else {
                return '';
            }

        });

        document.getElementById("anagraficheTitle").innerHTML = 'Anagrafiche: ' + profilesNumber;

    };

    return (
        <>

            <Navbar setAdminAuthenticationToken={setAdminAuthenticationToken} />

            <div className="container" style={{ marginTop: 20, marginBottom: 20 }} >
                <div className="row">
                    <div className="col">
                        <h4 id="anagraficheTitle">Anagrafiche:</h4>
                    </div>
                    <div className="col" style={{ textAlign: "right" }}>
                        <button type="button" className="btn btn-primary" onClick={() => window.location.assign("/admin/anagrafiche/add")}>Aggiungi nuova</button><br></br>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        Visualizza:&nbsp;
                        Buyer <input type="checkbox" id="chkShowBuyer" checked={showBuyer} onChange={() => setShowBuyer(!showBuyer)}></input>&nbsp;&nbsp;
                        Seller <input type="checkbox" id="chkShowSeller" checked={showSeller} onChange={() => setShowSeller(!showSeller)}></input>&nbsp;&nbsp;
                        Abilitati <input type="checkbox" id="chkShowAbilitati" checked={showAbilitati} onChange={() => setShowAbilitati(!showAbilitati)}></input>&nbsp;&nbsp;
                        Disabilitati <input type="checkbox" id="chkShowDisabilitati" checked={showDisabilitati} onChange={() => setShowDisabilitati(!showDisabilitati)}></input>&nbsp;&nbsp;
                    </div>
                </div>
            </div>

            <div className="container">

                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col"><span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => handleSortClick('ragioneSociale')}>Ragione sociale</span></th>
                            <th scope="col"><span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => handleSortClick('localita')}>Localita</span></th>
                            <th scope="col"><span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => handleSortClick('nazione')}>Nazione</span></th>
                            <th scope="col"><span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => handleSortClick('seller')}>Buyer/Seller</span></th>
                            <th scope="col"><span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => handleSortClick('abilitato')}>Abilitato</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        {profilesRows}
                    </tbody>
                </table>

            </div>

        </>
    )
}
