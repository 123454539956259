import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { getAdminWebClient } from '../../helpers/Helpers.js';
import Navbar from './components/Navbar';
import TipologiaDropdown from './components/TipologiaDropdown';

export default function AdminAnagraficheEdit({ setAdminAuthenticationToken }) {

    const { id } = useParams();
    const [profile, setprofile] = useState();
    const client = getAdminWebClient();
    const navigate = useNavigate();

    function handleOnChange() {
        setprofile(
            {
                "id": document.getElementById('txtId').value,
                "guid": document.getElementById('txtGuid').value,
                "token": document.getElementById('txtToken').value,
                "pin": document.getElementById('txtPin').value,
                "abilitato": document.getElementById('chkAbilitato').checked,
                "ragioneSociale": document.getElementById('txtRagioneSociale').value,
                "localita": document.getElementById('txtLocalita').value,
                "nazione": document.getElementById('txtNazione').value,
                "email": document.getElementById('txtEmail').value,
                "telefono": document.getElementById('txtTelefono').value,
                "referenteTitolo": document.getElementById('txtReferenteTitolo').value,
                "referenteNome": document.getElementById('txtReferenteNome').value,
                "referenteSesso": document.getElementById('txtReferenteSesso').value,
                "profilo": document.getElementById('txtProfilo').value,
                "seller": document.getElementById('chkSeller').checked,
                "idMacroregione": document.getElementById('selIdMacroregione').value,
                "idTipologia": document.getElementById('selIdTipologia').value,
                "elencoDateAbilitate": getDateAbilitate(),
                "dataRegistrazione": document.getElementById('txtDataRegistrazione').value,
                "idTabRicerca": document.getElementById('txtIdTabRicerca').value,
                "sitoInternet": document.getElementById('txtSitoInternet').value,
                "numeroStand": document.getElementById('txtNumeroStand').value

            }
        );
        console.log(profile);
    }

    function getDateAbilitate() {
        let dateAbilitate = '';
        if (document.getElementById('chkElencoDateAbilitate1').checked) { dateAbilitate += "1"; }
        if (document.getElementById('chkElencoDateAbilitate2').checked) { dateAbilitate += "2"; }
        if (document.getElementById('chkElencoDateAbilitate3').checked) { dateAbilitate += "3"; }
        return dateAbilitate;
    }

    function formHasErrors() {

        let errors = false;

        document.getElementById('txtGuid').classList.remove("is-invalid");
        document.getElementById('txtToken').classList.remove("is-invalid");
        document.getElementById('txtPin').classList.remove("is-invalid");
        document.getElementById('txtRagioneSociale').classList.remove("is-invalid");
        document.getElementById('txtLocalita').classList.remove("is-invalid");
        document.getElementById('txtNazione').classList.remove("is-invalid");
        document.getElementById('txtEmail').classList.remove("is-invalid");
        document.getElementById('txtTelefono').classList.remove("is-invalid");
        document.getElementById('txtReferenteTitolo').classList.remove("is-invalid");
        document.getElementById('txtReferenteNome').classList.remove("is-invalid");
        document.getElementById('txtReferenteSesso').classList.remove("is-invalid");
        document.getElementById('txtProfilo').classList.remove("is-invalid");
        document.getElementById('txtIdTabRicerca').classList.remove("is-invalid");
        document.getElementById('txtSitoInternet').classList.remove("is-invalid");
        document.getElementById('txtNumeroStand').classList.remove("is-invalid");

        document.getElementById('chkElencoDateAbilitate1').classList.remove("is-invalid");
        document.getElementById('chkElencoDateAbilitate2').classList.remove("is-invalid");
        document.getElementById('chkElencoDateAbilitate3').classList.remove("is-invalid");

        if (profile.abilitato) {

            if (profile.guid.length !== 36) { document.getElementById('txtGuid').classList.add("is-invalid"); errors = true; }
            if (profile.token.length !== 36) { document.getElementById('txtToken').classList.add("is-invalid"); errors = true; }
            if (profile.pin.length < 8) { document.getElementById('txtPin').classList.add("is-invalid"); errors = true; }
            if (profile.ragioneSociale.length < 1) { document.getElementById('txtRagioneSociale').classList.add("is-invalid"); errors = true; }
            if (profile.localita.length < 1) { document.getElementById('txtLocalita').classList.add("is-invalid"); errors = true; }
            if (profile.nazione.length < 1) { document.getElementById('txtNazione').classList.add("is-invalid"); errors = true; }
            if (profile.email.length < 1) { document.getElementById('txtEmail').classList.add("is-invalid"); errors = true; }
            if (profile.referenteTitolo.length < 1) { document.getElementById('txtReferenteTitolo').classList.add("is-invalid"); errors = true; }
            if (profile.referenteNome.length < 1) { document.getElementById('txtReferenteNome').classList.add("is-invalid"); errors = true; }
            if (profile.referenteSesso.length < 1) { document.getElementById('txtReferenteSesso').classList.add("is-invalid"); errors = true; }
            if (profile.idTabRicerca.length < 1) { document.getElementById('txtIdTabRicerca').classList.add("is-invalid"); errors = true; }

            if (profile.elencoDateAbilitate === '') {
                document.getElementById('chkElencoDateAbilitate1').classList.add("is-invalid");
                document.getElementById('chkElencoDateAbilitate2').classList.add("is-invalid");
                document.getElementById('chkElencoDateAbilitate3').classList.add("is-invalid");
                errors = true;
            }

        } else {

            if (profile.guid.length !== 36) { document.getElementById('txtGuid').classList.add("is-invalid"); errors = true; }
            if (profile.token.length !== 36) { document.getElementById('txtToken').classList.add("is-invalid"); errors = true; }
            if (profile.pin.length < 8) { document.getElementById('txtPin').classList.add("is-invalid"); errors = true; }
            if (profile.ragioneSociale.length < 1) { document.getElementById('txtRagioneSociale').classList.add("is-invalid"); errors = true; }
            if (profile.idTabRicerca.length < 1) { document.getElementById('txtIdTabRicerca').classList.add("is-invalid"); errors = true; }

        }

        return errors;
    };

    function handleUpdateClick() {

        if (!formHasErrors()) {
            client.put('/api/admin/managecompanydata/' + profile.id, profile)
                .then(function (response) {
                    navigate("/admin/anagrafiche");
                })
                .catch(function (error) {
                    alert("Si sono verificati errori durante l'operazione di aggiornamento. Verificare i dati introdotti e riprovare.")
                    console.log(error);
                });
        } else {
            alert('I dati sono incompleti, la modifica anagrafica non \u00E9 stata eseguita.');
        }

    }

    function handleDeleteClick() {

        if (window.confirm("Vuoi davvero eliminare questa anagrafica?")) {
        
            client.delete('/api/admin/managecompanydata/' + profile.id)
                .then(function (response) {
                    navigate("/admin/anagrafiche");
                })
                .catch(function (error) {
                    alert("Si sono verificati errori durante l'operazione di eliminazione.")
                    console.log(error);
                });

        }
    }

    useEffect(() => {

        client.get(`/api/admin/managecompanydata/${id}`)
            .then(function (response) {
                setprofile(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });

    }, []);

    if (profile == null) { return ''; }

    return (
        <>

            <Navbar setAdminAuthenticationToken={setAdminAuthenticationToken} />

            <div className="container" style={{ marginTop: 20, marginBottom: 20 }} >
                <div className="row">
                    <div className="col">
                        <h4>Anagrafiche - Modifica / Eliminazione</h4>
                    </div>
                </div>
            </div>

            <div className="container">
                <form>

                    <div className="row mb-3">
                        <label htmlFor="txtId" className="col-sm-2 col-form-label">
                            Id
                        </label>
                        <div className="col-sm-10">
                            <input type="text" readOnly className="form-control" id="txtId" value={profile.id} onChange={handleOnChange} maxLength="36" />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label htmlFor="inputGuid" className="col-sm-2 col-form-label">
                            Guid
                        </label>
                        <div className="col-sm-10">
                            <input type="text" readOnly className="form-control" id="txtGuid" value={profile.guid} onChange={handleOnChange} maxLength="36" />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label htmlFor="txtToken" className="col-sm-2 col-form-label">
                            Token
                        </label>
                        <div className="col-sm-10">
                            <input type="text" readOnly className="form-control" id="txtToken" value={profile.token} onChange={handleOnChange} maxLength="36" />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label htmlFor="txtPin" className="col-sm-2 col-form-label">
                            Pin
                        </label>
                        <div className="col-sm-10">
                            <input type="text" className="form-control" id="txtPin" value={profile.pin} onChange={handleOnChange} maxLength="50" />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label htmlFor="chkAbilitato" className="col-sm-2 col-form-label">
                            Abilitato
                        </label>
                        <div className="col-sm-10">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="chkAbilitato"
                                checked={profile.abilitato}
                                onChange={handleOnChange}
                            />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label htmlFor="inputRagioneSociale" className="col-sm-2 col-form-label">
                            Ragione Sociale
                        </label>
                        <div className="col-sm-10">
                            <input type="text" className="form-control" id="txtRagioneSociale" value={profile.ragioneSociale} onChange={handleOnChange} maxLength="250" />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label htmlFor="inputLocalita" className="col-sm-2 col-form-label">
                            Localit&agrave;
                        </label>
                        <div className="col-sm-10">
                            <input type="text" className="form-control" id="txtLocalita" value={profile.localita} onChange={handleOnChange} maxLength="100" />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label htmlFor="inputNazione" className="col-sm-2 col-form-label">
                            Nazione
                        </label>
                        <div className="col-sm-10">
                            <input type="text" className="form-control" id="txtNazione" value={profile.nazione} onChange={handleOnChange} maxLength="100" />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label htmlFor="inputEmail" className="col-sm-2 col-form-label">
                            Email
                        </label>
                        <div className="col-sm-10">
                            <input type="email" className="form-control" id="txtEmail" value={profile.email} onChange={handleOnChange} maxLength="64" />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label htmlFor="inputTelefono" className="col-sm-2 col-form-label">
                            Telefono
                        </label>
                        <div className="col-sm-10">
                            <input type="text" className="form-control" id="txtTelefono" value={profile.telefono} onChange={handleOnChange} maxLength="50" />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label htmlFor="inputReferenteTitolo" className="col-sm-2 col-form-label">
                            Referente Titolo
                        </label>
                        <div className="col-sm-10">
                            <input type="text" className="form-control" id="txtReferenteTitolo" value={profile.referenteTitolo} onChange={handleOnChange} maxLength="50" />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label htmlFor="inputReferenteNome" className="col-sm-2 col-form-label">
                            Referente Nome
                        </label>
                        <div className="col-sm-10">
                            <input type="text" className="form-control" id="txtReferenteNome" value={profile.referenteNome} onChange={handleOnChange} maxLength="50" />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label htmlFor="inputReferenteSesso" className="col-sm-2 col-form-label">
                            Referente Sesso
                        </label>
                        <div className="col-sm-10">
                            <input type="text" className="form-control" id="txtReferenteSesso" value={profile.referenteSesso} onChange={handleOnChange} maxLength="1" />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label htmlFor="inputProfilo" className="col-sm-2 col-form-label">
                            Profilo
                        </label>
                        <div className="col-sm-10">
                            <textarea className="form-control" id="txtProfilo" value={profile.profilo} onChange={handleOnChange} maxLength="3500" />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label htmlFor="chkSeller" className="col-sm-2 col-form-label">
                            Seller
                        </label>
                        <div className="col-sm-10">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="chkSeller"
                                checked={profile.seller}
                                onChange={handleOnChange}
                            />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label htmlFor="selIdMacroregione" className="col-sm-2 col-form-label">
                            Id Macroregione
                        </label>
                        <div className="col-sm-10">
                            <select className="form-select" id="selIdMacroregione" onChange={handleOnChange} value={profile.idMacroregione}>
                                <option value="2">Africa</option>
                                <option value="10">Arabian Emirates</option>
                                <option value="3">Asia</option>
                                <option value="5">Central/South America</option>
                                <option value="7">Central Europe</option>
                                <option value="18">Central Italy</option>
                                <option value="15">Eastern Europe</option>
                                <option value="20">Eastern Europe</option>
                                <option value="13">Italy</option>
                                <option value="8">Mediterranean Europe</option>
                                <option value="9">Middle Orient</option>
                                <option value="4">North America</option>
                                <option value="6">North Europe</option>
                                <option value="17">Northen Italy</option>
                                <option value="12">Oceania</option>
                                <option value="11">Russia</option>
                                <option value="14">Scandinavia</option>
                                <option value="19">Southern Italy and Islands</option>
                                <option value="16">Switzerland</option>
                            </select>
                        </div>
                    </div>

                    <TipologiaDropdown IsSeller={profile.seller} HandleOnChange={handleOnChange} Value={profile.idTipologia}></TipologiaDropdown>

                    <div className="row mb-3">
                        <label htmlFor="inputElencoDateAbilitate" className="col-sm-2 col-form-label">
                            Elenco Date Abilitate
                        </label>
                        <div className="col-sm-10">
                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="chkElencoDateAbilitate1" checked={profile.elencoDateAbilitate.includes("1")} value="1" onChange={handleOnChange}></input>
                                <label className="form-check-label" htmlFor="chkElencoDateAbilitate1">
                                    1 nov
                                </label>
                            </div>
                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="chkElencoDateAbilitate2" checked={profile.elencoDateAbilitate.includes("2")} value="2" onChange={handleOnChange}></input>
                                <label className="form-check-label" htmlFor="chkElencoDateAbilitate2">
                                    2 nov
                                </label>
                            </div>
                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="chkElencoDateAbilitate3" checked={profile.elencoDateAbilitate.includes("3")} value="3" onChange={handleOnChange}></input>
                                <label className="form-check-label" htmlFor="chkElencoDateAbilitate3">
                                    3 nov
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label htmlFor="inputDataRegistrazione" className="col-sm-2 col-form-label" maxLength="10">
                            Data Registrazione
                        </label>
                        <div className="col-sm-10">
                            <input type="rexr" readOnly className="form-control" id="txtDataRegistrazione" value={profile.dataRegistrazione} onChange={handleOnChange} />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label htmlFor="inputIdTabRicerca" className="col-sm-2 col-form-label">
                            Id TabRicerca
                        </label>
                        <div className="col-sm-10">
                            <input type="number" className="form-control" id="txtIdTabRicerca" value={profile.idTabRicerca} onChange={handleOnChange} maxLength="10" />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label htmlFor="inputSitoInternet" className="col-sm-2 col-form-label">
                            Sito Internet
                        </label>
                        <div className="col-sm-10">
                            <input type="text" className="form-control" id="txtSitoInternet" value={profile.sitoInternet} onChange={handleOnChange} maxLength="150" />
                        </div>
                    </div>

                    <div className="row mb-3">
                        <label htmlFor="inputNumeroStand" className="col-sm-2 col-form-label">
                            Numero Stand
                        </label>
                        <div className="col-sm-10">
                            <input type="text" className="form-control" id="txtNumeroStand" value={profile.numeroStand} onChange={handleOnChange} maxLength="10" />
                        </div>
                    </div>

                    <button type="button" className="btn btn-primary" onClick={() => window.location.assign("/admin/anagrafiche")}>Indietro</button>&nbsp;
                    <button type="button" className="btn btn-primary" onClick={() => handleUpdateClick()}>Procedi con la modifica</button>&nbsp;
                    <button type="button" className="btn btn-primary" onClick={() => handleDeleteClick()}>Elimina questa anagrafica</button>

                </form>

            </div>

            <br></br><br></br>


        </>
    );
}
