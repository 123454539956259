import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { getWebClient } from '../helpers/Helpers.js';
import Spinner from './components/Spinner';


export default function Print({ readonlySwitchDateTime }) {

    const [viewMode, setViewMode] = useState('waiting');
    const [agenda1, setAgenda1] = useState();
    const [agenda2, setAgenda2] = useState();
    const [agenda3, setAgenda3] = useState();
    const navigate = useNavigate();
    const client = getWebClient();

    let backButton;

    useEffect(() => {

        client.get(`/api/getagenda/1`)
            .then(function (response) {
                setAgenda1(response.data);

                client.get(`/api/getagenda/2`)
                    .then(function (response) {
                        setAgenda2(response.data);

                        client.get(`/api/getagenda/3`)
                            .then(function (response) {
                                setAgenda3(response.data);
                                setViewMode('');
                            })
                            .catch(function (error) {
                                console.log(error);
                                setViewMode('error');
                            });

                    })
                    .catch(function (error) {
                        console.log(error);
                        setViewMode('error');
                    });

            })
            .catch(function (error) {
                console.log(error);
                setViewMode('error');
            });

    }, []);

    if (Date.now() > readonlySwitchDateTime) {
        backButton = (
            <>
                <div className="col-md-12 text-center" style={{ marginTop: 30, marginBottom: 40 }}>
                    <div className="back-btn">
                        <button className="btn btn-secondary" onClick={() => window.print()}>Print</button>
                    </div>
                </div>
            </>    
        );
    } else {
        backButton = (
            <>
                <div className="col-md-12 text-center" style={{ marginTop: 30, marginBottom: 40 }}>
                    <div className="back-btn">
                        <button className="btn btn-secondary" onClick={() => window.print()}>Print</button>
                    </div>
                    <div className="back-btn">
                        <button className="btn btn-secondary" onClick={() => navigate(-1)}>Back</button>
                    </div>
                </div>
            </>
        );
    }


    if (viewMode !== '') {


        return (
            <>
                <Spinner></Spinner>
            </>
        );

    } else {

        let agendaDays1 = agenda1.map((appointment, index) => {

            if (appointment.abilitato) {

                return (
                    <table className="table ma-table">
                        <tbody>
                            <tr>
                                <td rowSpan="2" className="table-btns">{appointment.oraDa} - {appointment.oraA}</td>
                                <td className="ps-2 w-260" >{appointment.partner ? 'Appointment with ' + appointment.partner.ragioneSociale : 'The appointment is free'}</td>
                            </tr>
                            <tr>
                                <td className="w-260">{appointment.queuedPartner ? 'Queuing with ' + appointment.queuedPartner.ragioneSociale : 'None is queuing'}</td>
                            </tr>
                        </tbody>
                    </table>
                )

            } else {

                return (
                    <table className="table ma-table">
                        <tbody>
                            <tr>
                                <td rowSpan="2" className="table-btns">{appointment.oraDa} - {appointment.oraA}</td>
                                <td className="ps-2 w-260" >You are not enabled for this date/time</td>
                            </tr>
                            <tr>
                                <td className="w-260">Your queue is not enabled for this date/time</td>
                            </tr>
                        </tbody>
                    </table>
                )

            }

        });

        let agendaDays2 = agenda2.map((appointment, index) => {

            if (appointment.abilitato) {

                return (
                    <table className="table ma-table">
                        <tbody>
                            <tr>
                                <td rowSpan="2" className="table-btns">{appointment.oraDa} - {appointment.oraA}</td>
                                <td className="ps-2 w-260" >{appointment.partner ? 'Appointment with ' + appointment.partner.ragioneSociale : 'The appointment is free'}</td>
                            </tr>
                            <tr>
                                <td className="w-260">{appointment.queuedPartner ? 'Queuing with ' + appointment.queuedPartner.ragioneSociale : 'None is queuing'}</td>
                            </tr>
                        </tbody>
                    </table>
                )

            } else {

                return (
                    <table className="table ma-table">
                        <tbody>
                            <tr>
                                <td rowSpan="2" className="table-btns">{appointment.oraDa} - {appointment.oraA}</td>
                                <td className="ps-2 w-260" >You are not enabled for this date/time</td>
                            </tr>
                            <tr>
                                <td className="w-260">Your queue is not enabled for this date/time</td>
                            </tr>
                        </tbody>
                    </table>
                )

            }

        });

        let agendaDays3 = agenda3.map((appointment, index) => {

            if (appointment.abilitato) {

                return (
                    <table className="table ma-table">
                        <tbody>
                            <tr>
                                <td rowSpan="2" className="table-btns">{appointment.oraDa} - {appointment.oraA}</td>
                                <td className="ps-2 w-260" >{appointment.partner ? 'Appointment with ' + appointment.partner.ragioneSociale : 'The appointment is free'}</td>
                            </tr>
                            <tr>
                                <td className="w-260">{appointment.queuedPartner ? 'Queuing with ' + appointment.queuedPartner.ragioneSociale : 'None is queuing'}</td>
                            </tr>
                        </tbody>
                    </table>
                )

            } else {

                return (
                    <table className="table ma-table">
                        <tbody>
                            <tr>
                                <td rowSpan="2" className="table-btns">{appointment.oraDa} - {appointment.oraA}</td>
                                <td className="ps-2 w-260" >You are not enabled for this date/time</td>
                            </tr>
                            <tr>
                                <td className="w-260">Your queue is not enabled for this date/time</td>
                            </tr>
                        </tbody>
                    </table>
                )

            }

        });

        return (
            <>

                <section className="buyer-sec ma-sec">
                    <header className="header">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <img src="assets/images/logo.svg" alt="" />
                            </div>
                            <div className="col-md-6">
                                <h2>Workshop Agenda</h2>
                                <p>Switzerland, 1/3 November 2024</p>
                            </div>
                        </div>
                    </header>
                    <div className="container ma-container">
                        <div className="row">
                            <p className="main-heading" style={{ marginTop: 30, marginBottom: 40 }}>1ST NOVEMBER 2024</p>
                            <div className="col-md-12">
                                {agendaDays1}
                            </div>
                            <p className="main-heading" style={{ marginTop: 30, marginBottom: 40 }}>2ND NOVEMBER 2024</p>
                            <div className="col-md-12">
                                {agendaDays2}
                            </div>
                            <p className="main-heading" style={{ marginTop: 30, marginBottom: 40 }}>3RD NOVEMBER 2024</p>
                            <div className="col-md-12">
                                {agendaDays3}
                            </div>
                            {backButton}
                        </div>
                    </div>
                </section>

            </>
        );

    }

}