import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Activity from './pages/Activity';
import Agenda from './pages/Agenda';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import NotFound from './pages/NotFound';
import PartnerProfile from './pages/PartnerProfile';
import PartnerAgenda from './pages/PartnerAgenda';
import Print from './pages/Print';
import Profile from './pages/Profile';
import Search from './pages/Search';

import AdminLogin from './pages/admin/AdminLogin';
import AdminDashboard from './pages/admin/AdminDashboard';
import AdminAnagrafiche from './pages/admin/AdminAnagrafiche';
import AdminAnagraficheAdd from './pages/admin/AdminAnagraficheAdd';
import AdminAnagraficheEdit from './pages/admin/AdminAnagraficheEdit';
import AdminAppuntamentiBuyer from './pages/admin/AdminAppuntamentiBuyer';
import AdminStampaAgendeBuyer from './pages/admin/AdminStampaAgendeBuyer';
import AdminStampaAgendeSeller from './pages/admin/AdminStampaAgendeSeller';
import AdminNotFound from './pages/admin/AdminNotFound';

const Theme = React.lazy(() => import('./Theme'));

function App() {

    const [authenticationToken, setAuthenticationToken] = useState(localStorage.getItem("authenticationToken"));
    const [adminAuthenticationToken, setAdminAuthenticationToken] = useState(localStorage.getItem("adminAuthenticationToken"));
    const readonlySwitchDateTime = new Date('2024-10-30T23:59:59');

    if (!window.location.href.includes('/admin')) {

        if (!authenticationToken) {
            return (
                <>
                    <React.Suspense fallback={<><Theme></Theme></>}>
                        <Theme></Theme>
                    </React.Suspense>
                    <div className="wrapper">
                        <BrowserRouter>
                            <Routes>
                                <Route path="/" element={<Login setAuthenticationToken={setAuthenticationToken} />}>
                                </Route>
                                <Route path="*" element={<NotFound />}>
                                </Route>
                            </Routes>
                        </BrowserRouter>
                    </div>
                </>
            );
        }

        if (Date.now() > readonlySwitchDateTime) {

            return (
                <>
                    <React.Suspense fallback={<><Theme></Theme></>}>
                        <Theme></Theme>
                    </React.Suspense>
                    <div className="wrapper">
                        <BrowserRouter>
                            <Routes>
                                <Route path="/" element={<Print readonlySwitchDateTime={readonlySwitchDateTime} />}>
                                </Route>
                                <Route path="/admin" element={<AdminLogin />}>
                                </Route>
                                <Route path="*" element={<NotFound />}>
                                </Route>
                            </Routes>
                        </BrowserRouter>
                    </div>
                </>
            );

        } else {

            return (
                <>
                    <React.Suspense fallback={<><Theme></Theme></>}>
                        <Theme></Theme>
                    </React.Suspense>
                    <div className="wrapper">
                        <BrowserRouter>
                            <Routes>
                                <Route path="/" element={<Dashboard setAuthenticationToken={setAuthenticationToken} />}>
                                </Route>
                                <Route path="/activity" element={<Activity />}>
                                </Route>
                                <Route path="/agenda" element={<Agenda />}>
                                </Route>
                                <Route path="/partnerprofile/:partnerid" element={<PartnerProfile />}>
                                </Route>
                                <Route path="/partneragenda/:partnerid" element={<PartnerAgenda />}>
                                </Route>
                                <Route path="/print" element={<Print />}>
                                </Route>
                                <Route path="/profile" element={<Profile />}>
                                </Route>
                                <Route path="/search" element={<Search />}>
                                </Route>
                                <Route path="/admin" element={<AdminLogin />}>
                                </Route>
                                <Route path="*" element={<NotFound />}>
                                </Route>
                            </Routes>
                        </BrowserRouter>
                    </div>
                </>
            );

        }

    } else {

        if (!adminAuthenticationToken) {
            return (
                <>                   
                    <div className="wrapper">
                        <BrowserRouter>
                            <Routes>
                                <Route path="/admin" element={<AdminLogin setAdminAuthenticationToken={setAdminAuthenticationToken}></AdminLogin>}>
                                </Route>
                                <Route path="*" element={<NotFound />}>
                                </Route>
                            </Routes>
                        </BrowserRouter>
                    </div>
                </>
            );
        }

        return (
            <div className="wrapper">
                <BrowserRouter>
                    <Routes>
                        <Route path="/admin" element={<AdminDashboard setAdminAuthenticationToken={setAdminAuthenticationToken} />}>
                        </Route>
                        <Route path="/admin/anagrafiche" element={<AdminAnagrafiche setAdminAuthenticationToken={setAdminAuthenticationToken} />}>
                        </Route>
                        <Route path="/admin/anagrafiche/add" element={<AdminAnagraficheAdd setAdminAuthenticationToken={setAdminAuthenticationToken} />}>
                        </Route>
                        <Route path="/admin/anagrafiche/edit/:id" element={<AdminAnagraficheEdit setAdminAuthenticationToken={setAdminAuthenticationToken} />}>
                        </Route>
                        <Route path="/admin/appuntamentibuyer" element={<AdminAppuntamentiBuyer />}>
                        </Route>
                        <Route path="/admin/stampaagendeseller" element={<AdminStampaAgendeSeller />}>
                        </Route>
                        <Route path="/admin/stampaagendebuyer" element={<AdminStampaAgendeBuyer />}>
                        </Route>
                        <Route path="*" element={<AdminNotFound />}>
                        </Route>
                    </Routes>
                </BrowserRouter>
            </div>
        );

    }

}

export default App;
