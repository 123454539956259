import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

export default function AdminNotFound() {

    const navigate = useNavigate();    

    useEffect(() => {
        navigate("/admin");
    }, []);

    return;


}